import React from 'react';
import MobileAppHero from 'static/landing/components/MobileAppHero';
import Layout from 'common/page-layout/Layout';

const AppLandingPage = () => (
  <Layout>
    <MobileAppHero css="margin-top: 30px" />
  </Layout>
);

export default AppLandingPage;
